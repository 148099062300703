import $ from 'jquery';
import Cookie from 'js-cookie';
import 'bootstrap';
import boostrap from 'bootstrap/dist/js/bootstrap.bundle';
// import 'mdbootstrap/js/mdb.min';
// for privacy handling
const THE_COOKIE = '_ist_privacy_consent';
const GA_DISABLE = 'ga-disable-UA-3949815-4';
window[GA_DISABLE] = true;

$(function () {

    const consent = Cookie.get(THE_COOKIE);
    const consentPanel = $('#enableCookies');

    if (undefined === consent) {
        consentPanel.css('display', 'block');
    }

    // event handlers buttons on the cookie consent dialog
    $('#btn-consent-agree').on('click', function (ev) {
        Cookie.set(THE_COOKIE, 'true', {expires: 7 * 4 * 3}); // 7 days * 4 weeks * 3 months
        consentPanel.hide();
        location.reload();
    });
    $('#btn-consent-disagree').on('click', function (ev) {
        Cookie.set(THE_COOKIE, 'false', {expires: 7 * 4 * 3}); // 7 days * 4 weeks * 3 months
        consentPanel.hide();
    });
    $('#btn-consent-reset').on('click', function (ev) {
        Cookie.remove(THE_COOKIE);
        location.reload();
    });

    if (consent === 'true') { // enable services with cookies only explicitly "true" is set
        window[GA_DISABLE] = false;
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-PP9D4Z5');

        $('#bot-container').css('display', 'block');
        $('#fab-bot').click(function (ev) {
            ev.preventDefault();
            ev.stopPropagation();
            // activate the modal
            $('#modal-chatbot').modal('show');
        });
    }

    if (window.matchMedia('(max-width: 991px)').matches) {
        $("#spnav").addClass('show').removeClass('hidden');
        $("#pcnav").addClass('hidden').removeClass('show');
    } else {
        $("#pcnav").addClass('show').removeClass('hidden');
        $("#spnav").addClass('hidden').removeClass('show');
        $(".sp-scroll").removeClass('no-scroll').off('.noScroll');
    }

    // スムーススクロール ※ページ外からのアンカーリンクでも効くように
    const headerHeight = $('.fixed-top').outerHeight() + 20;
    const urlHash = location.hash;
    if (urlHash) {
        $('body,html').stop().scrollTop(0);
        setTimeout(function () {
            const target = $(urlHash);
            const position = target.offset().top - headerHeight;
            $('body,html').stop().animate({scrollTop: position}, 500);
        }, 100);
    }
    $('a[href^="#"]:not([id^="MM"])').click(function () {
        if ($(this).attr('id') === "fab-bot") {
            return false;
        }
        let href = $(this).attr("href");
        href = ('#' === href) ? '#pagetop' : href;
        const target = $(href);
        const position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop: position}, 500);
    });

    // オーバーレイメニュー背景のスクロールを禁止
    $("#menu-open").click(function () {
        $("#overlay").fadeIn();
        $(".sp-scroll").addClass('no-scroll').on('touchmove.noScroll', function (e) {
            e.preventDefault();
        });
    });
    $("#menu-close").click(function () {
        $("#overlay").fadeOut();
        $(".sp-scroll").removeClass('no-scroll').off('.noScroll');
    });


    // Tooltips Initialization
    $('[data-toggle="tooltip"]').tooltip();

    // popovers Initialization
    $('[data-toggle="popover"]').popover({
        html: true,
        trigger: 'hover focus',
    });

    //$("[data-toggle=popover]").popover({
    //    html: true,
    //    container: 'body',
    //    content: function () {
    //        var contentDivId = '#' + $(this).data('content_div_id');
    //        return $(contentDivId).html();
    //    },
    //    trigger: 'hover focus'
    //});


    // chatbot window
    // insert a modal codes
    $('#display-chat').html('' +
        '<div class="modal fade right"  data-backdrop="false" id="modal-chatbot" tabindex="-1" role="dialog" aria-labelledby="チャットボット" aria-hidden="true">\n' +
        '<div class="modal-dialog modal-side modal-bottom-right" role="document">\n' +
        '<div class="modal-content">\n' +
        '<div class="modal-header">\n' +
        '<h5 class="modal-title"><i class="material-icons" translate="no">live_help</i>Q&A bot</h5>\n' +
        '<button type="button" class="close" data-dismiss="modal" aria-label="Close">\n' +
        '<span aria-hidden="true">×</span>\n' +
        '</button>\n' +
        '</div>' +
        '<div class="modal-body">\n' +
        '<iframe id="iframe-chatbot" src="/chatbot/index.html"></iframe>' +
        '</div>\n' +
        '</div>\n' +
        '</div>\n' +
        '</div>');

    $('.bing-translate').click(function (ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const current = encodeURIComponent(window.location.href);
        const bing = $(this).attr('href');
        window.open().location.href = bing + current;
    });
    $('.bing-translate-en').click(function (ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const current = encodeURIComponent(window.location.host) + '/eng';
        const bing = $(this).attr('href');
        window.open().location.href = bing + current;
    });
});
